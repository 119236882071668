import React from 'react'
import { Fade } from 'react-reveal'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-styled-flexboxgrid'
import { DiscussionEmbed } from 'disqus-react'
import Img from 'gatsby-image'
import _ from 'lodash'
import Layout from '../components/Layout'

import {
  HeaderNewsPost,
  ContainerAuthor,
  AuthorDescription,
  RichContent,
} from './news-post.styled'
import Tags from '../components/Tags'
import ShareLinks from '../components/ShareLinks'
import RelatedNews from '../components/RelatedNews'
import ImageInitials from '../components/Common/ImageInitials'
import SEO from '../components/seo'
import { Subtitle } from '../components/Categories/Categories.styled'
import Icon from '../components/Common/Icon'
import { CustomLink } from '../components/Header/DropdownContent/Content.styled'

const NewsPost = props => {
  const post = _.get(props, 'data.contentfulNews')
  const relatedNews = _.get(props, 'data.allContentfulNews.edges')
  const disqusShortname = 'ascg'
  const disqusConfig = {
    identifier: post.id,
    title: post.title,
  }
  const description = post.description.childMarkdownRemark.html
  return (
    <Layout location={props.location}>
      <SEO
        title={post.title}
        description={description && description.replace(/<[^>]*>/g, '')}
        image={post.heroImage.fluid.src}
        url={props.location.href}
      />
      <HeaderNewsPost>
        <Fade bottom distance="20px" duration={400}>
          <Row center="xs" middle="xs">
            <Col xs={11} lg={7}>
              <div>
                <h1 style={{ color: 'white', maxWidth: '800px' }}>
                  {post.title}
                </h1>
                <Img
                  alt={post.title}
                  fluid={post.heroImage.fluid}
                  style={{ height: '300px' }}
                />
                {/* <div
                  style={{
                    fontSize: 'small',
                    textAlign: 'right',
                    fontWeight: '400',
                    padding: '0 0.75rem',
                  }}
                >
                  {post.footerTextImage}
                </div> */}
              </div>
              <Tags tags={post.tags} />
              <div>
                <ContainerAuthor>
                  {post.author && post.author.image ? (
                    <Img
                      alt={post.author.name}
                      fluid={post.author.image.fluid}
                      style={{
                        minWidth: '150px',
                        height: '150px',
                        position: 'relative',
                        zIndex: 2,
                        objectFit: 'cover',
                        objectPosition: 'top',
                        borderRadius: '100px',
                      }}
                    />
                  ) : (
                    <ImageInitials
                      name={(post.author && post.author.name) || 'AS'}
                    />
                  )}
                  <AuthorDescription>
                    <span>
                      <span style={{ fontWeight: 'bold' }}>Escrito por:</span>{' '}
                      {(post.author && post.author.name) || 'AS News'}
                    </span>
                    <span style={{ display: 'block' }}>{post.publishDate}</span>
                    {post.author && post.author.email && (
                      <CustomLink
                        href={`mailto:${post.author.email}`}
                        noMargin
                        color="backgroundDark"
                      >
                        <Icon icon="at" size="lg" />
                      </CustomLink>
                    )}
                  </AuthorDescription>
                </ContainerAuthor>
                <RichContent
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
              </div>
              <div style={{ paddingTop: '1rem' }}>
                <h3>Compartir:</h3>
                <ShareLinks href={props.location.href} />
              </div>
              <DiscussionEmbed
                shortname={disqusShortname}
                config={disqusConfig}
              />
              <Subtitle>Noticias relacionadas</Subtitle>
              <RelatedNews relatedNews={relatedNews} />
            </Col>
          </Row>
        </Fade>
      </HeaderNewsPost>
    </Layout>
  )
}

export default NewsPost

export const pageQuery = graphql`
  query NewsBySlug($slug: String!, $categories: [String]) {
    contentfulNews(slug: { eq: $slug }) {
      id
      title
      tags
      author {
        name
        email
        image {
          fluid(maxWidth: 1200, maxHeight: 672) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      publishDate(formatString: "D/MM/YYYY")
      heroImage {
        fluid(maxWidth: 1200, maxHeight: 672) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { elemMatch: { slug: { in: $categories } } } }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D/MM/YY")
          heroImage {
            fluid(maxWidth: 400, maxHeight: 224) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
